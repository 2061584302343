@import '_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'

.header__row
    .col-xl-6
        width: 81% !important
.header__button
    font-weight: 500 !important
    padding-right: 1.4rem !important

.text-white
    color: $dark-color !important
.header__right-menu #CartItemCount
    color: $dark-color !important
.header__right-menu #headerCartIcon
    color: $dark-color !important

#additionalZone1Image a img,
#additionalZone2Image a img,
#additionalZone3Image a img,
#additionalZone4Image a img
    max-height: 150px
    width: auto !important

@media screen and (min-width: $min-screen-resolution-width) and (max-width: $max-screen-resolution-width)
    .header__button
        color: #959E14
        font-weight: 700 !important

.main-navigation nav button a
    color: #959E14
.main-navigation nav button a:hover
    color: #7f7f7f

#headerMenuOption_LoginToToolnetLink
    color: #959e14

#headerMenuOption_LoginToToolnetLink:hover
    color: #7f7f7f
